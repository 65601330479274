import React from "react";

export const Services = (props) => {
	return (
		<div id="services" className="text-center">
			<div className="container">
				<div className="section-title">
					<h2>Our Services</h2>
					<p>
						As a experienced freelance developers we offer you a wide range of
						services to develop your business
					</p>
				</div>
				<div className="row">
					{props.data
						? props.data.map((d, i) => (
								<div key={`${d.name}-${i}`} className="col-md-4">
									{" "}
									<img
										style={{ width: "200px" }}
										className={d.icon}
										src={d.icon}
										alt={d.name}
									></img>
									<div className="service-desc">
										<h3>{d.name}</h3>
										<p>{d.text}</p>
									</div>
								</div>
						  ))
						: "loading"}
				</div>
			</div>
		</div>
	);
};
